/*
<reinvite-dialog
    :show.sync="reinviting"
    :user="reinvite"
/>
*/
import { ReinviteDialog } from "../dialogs"

const is_user_inviter = {
    components: {
        ReinviteDialog
    },
    methods: {
        resend_invitation (user) {
            this.reinvite = user
            this.reinviting = true
        }
    },
    data () {
        return {
            reinviting: false,
            reinvite: {}
        }
    }
}

export default is_user_inviter
