import handles_errors from "./HandlesErrors"

const handles_saving = {
    mixins: [handles_errors],
    methods: {
        standardize_field_save_value (value, field) {
            if(this.$moment.isMoment(value)) {
                if(field.match(/_at$/)) return value.format("YYYY-MM-DD HH:mm:ss")
                return value.format("YYYY-MM-DD")
            }
            return value
        },
        save_data_for_record_id (entity, record_id, data) {
            if(!record_id) return

            const fields = Object.keys(data)
            fields.forEach(field => {
                if(this.saving_field_ids.indexOf(field) < 0) this.saving_field_ids.push(field)
                this.clear_error(field)
            })

            return this.$store.dispatch("RECORD_SAVE", {
                entity: entity,
                id: record_id,
                data
            }).catch(this.receive_error).then(() => {
                fields.forEach(field => {
                    const index = this.saving_field_ids.indexOf(field)
                    if(index >= 0) this.saving_field_ids.splice(index, 1)
                })
            })
        },
        save_field_for_record_id (entity, record_id, value, field) {
            return this.save_data_for_record_id(entity, record_id, { [field]: this.standardize_field_save_value(value, field) })
        },
        save_field (value, field) {
            return this.save_field_for_record_id(this.entity, this.record_id, value, field)
        },
        save_date (date, field, format = "YYYY-MM-DD") {
            const value = this.$moment.isMoment(date) ? date.format(format) : null
            this.save_field(value, field)
        },
        saving (field) {
            return (this.saving_field_ids.indexOf(field) >= 0)
        }
    },
    data () {
        return {
            record_id: 0,
            saving_field_ids: [],

            // NEEDS TO BE OVERRIDEN
            entity: ""
        }
    }
}

export default handles_saving
