const string_utilities = {
    methods: {
        stripe_duration_units (unit_name, interval, interval_count, separator = " / ", abbreviated = true) {
            if(!interval || !interval.match(/^(day|week|month|year)$/)) return ""
            let text = ""
            if(unit_name) text += `${separator}${unit_name}`
            text += separator
            if(abbreviated && (interval !== "day")) {
                let abbreviation = this.translate("wk")
                if(interval === "year") abbreviation = this.translate("yr")
                else if(interval === "month") abbreviation = this.translate("mo")
                text += this.translate(abbreviation)
            } else {
                text += `${this.translate(`${interval}:::{n} ${interval}s`, { n: interval_count }, interval_count)}`
            }
            return text
        },
        stripe_amount (number, interval, interval_count, unit_name = null, format = "0,0[.]00", force_as_amount = false) {
            if(unit_name === null) unit_name = this.translate("student:::students", {}, 1)
            if(!number && !force_as_amount) return this.translate("Free")
            let text = this.nibnut_filter("nibnut.currency", [number / 100, format, force_as_amount ? "" : this.translate("Free")])
            text += this.stripe_duration_units(unit_name, interval, interval_count)
            return text
        },
        stripe_plan_price (plan, price, unit_name) {
            return this.stripe_amount(price, plan.interval, plan.interval_count, unit_name)
        },
        coupon_terms (coupon) {
            const terms = []
            if(coupon) {
                if(coupon.amount_off) terms.push(this.nibnut_filter("nibnut.currency", [coupon.amount_off / 100]))
                else terms.push(`${this.nibnut_filter("nibnut.number", [coupon.percent_off / 100, "0,0[.]00"])}%`)
            }
            terms.push(this.$root.translate("off"))
            switch (coupon.duration) {
            case "once":
                terms.push(this.$root.translate("once"))
                break
            case "forever":
                terms.push(this.$root.translate("forever"))
                break
            case "repeating":
                terms.push(this.$root.translate("1 month:::{count} months", { count: coupon.duration_in_months }, coupon.duration_in_months))
                break
            }
            return terms.join(" ")
        },
        string_concat () {
            // (separator, component1, component2, ...)
            if(arguments.length < 2) return ""
            const separator = arguments[0]
            const components = Array.from(arguments).slice(1)
            return components.filter(component => !!component).join(separator)
        },
        address_block_line (line, address) {
            if(line === 1) {
                return address.line1
            } else if(line === 2) {
                return this.string_concat(", ", address.city, this.string_concat(" ", address.state, address.zip))
            }
            return ""
        },
        address_one_line (address) {
            return this.string_concat(", ", this.address_block_line(1, address), this.address_block_line(2, address))
        },
        full_name (record) {
            return this.string_concat(" ", record.first_name, record.last_name)
        }
    },
    computed: {
        app_version () {
            // eslint-disable-next-line
            if(process.env.VUE_APP_VERSION) return process.env.VUE_APP_VERSION
            return ""
        },
        current_year () {
            return this.$moment().format("YYYY")
        }
    }
}

export default string_utilities
