import misc_utilities from "./MiscUtilities"

const handles_recaptcha = {
    mixins: [misc_utilities],
    methods: {
        verify_recaptcha (response) {
            this.recaptcha = response
            if(this.verification_callback) this.verification_callback()
        }
    },
    computed: {
        recaptcha_key () {
            if(this.app_context && this.app_context.recaptcha_key) return this.app_context.recaptcha_key
            return ""
        }
    },
    data () {
        return {
            recaptcha: "",
            verification_callback: null
        }
    }
}

export default handles_recaptcha
